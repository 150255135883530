
import { Component, Vue } from 'vue-property-decorator';
import { AuthService, UserService } from '@/services';
import { UserModule } from '@/store/modules';
import { validate, ValidationProvider } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import OnboardLayout from '@/components/OnboardLayout.vue';
import Title from '@/components/common/Title.vue';

@Component({
  components: { OnboardLayout, Title, ValidationProvider }
})
export default class EditEmail extends Vue {
  email = '';
  validEmail = false;

  toAccountVerify() {
    this.$router.replace({
      path: '/account-verification'
    });
  }

  checkEmailValid() {
    validate(this.email, 'required|email', {
      name: 'Email', // name in the Validation Provdier
      values: {
        email // rules
      }
    }).then((result) => {
      if (result.valid) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    });
  }

  async updateAndResend(): Promise<void> {
    if (!this.validEmail) return;
    else {
      try {
        await AuthService.updateAndResend(this.email);
        let user = await UserService.fetchUser();
        UserModule.setUser(user);
      } catch (e: any) {
        const errorMsg = this.$getError(e);
        this.$notifyError(errorMsg);

        return;
      }

      this.$router.replace({
        path: '/account-verification'
      });
    }
  }
}
